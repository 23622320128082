<template>
    <div v-if="form.access === 1">
        <app-drawer_menu drawer_menu_title="Report Outstanding"></app-drawer_menu>
        <v-tabs
	      background-color="white"
	      color="fourth"
	    >
        <v-tab
            id       = "tabform"
            href   	 ="#tab-form"
        >
            Form
        </v-tab>
        <v-tab-item
            value  ="tab-form"
        >
        <v-progress-linear
	      indeterminate
	      color="fourth"
	      rounded
	      v-if="loading.page"
	    ></v-progress-linear>
        <!-- Loading Upload -->
        <h4>{{loading.upload}}</h4>
        <!-- Upload Hidden -->
        <v-form enctype="multipart/form-data" ref="form_report_production" lazy-validation>
			<v-row class="mx-2">
				<!-- Form Reins Period End Date-->
				<v-col cols="12" sm="2" md="2">
				<v-menu
					ref="dialog_asat"
					v-model="modal.asat"
					transition="scale-transition"
					persistent
					width="290px"
				>
					<template v-slot:activator="{ on }">
					<v-text-field
						v-model="form.asat"
						label="As At"
						prepend-inner-icon="event"
						background-color="date"
						v-on="on"
						v-mask="'####-##-##'"
						placeholder="YYYY-MM-DD"
					></v-text-field>
					</template>
					<v-date-picker
					v-model="form.asat"
					no-title
					scrollable
					>
					<v-spacer></v-spacer>
					<v-btn text color="primary" @click="modal.asat = false"
						>Cancel</v-btn
					>
					<v-btn
						text
						color="primary"
						@click="$refs.dialog_asat.save(form.asat)"
						>OK</v-btn
					>
					</v-date-picker>
				</v-menu>
				</v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn
				      color		="primary"
				      class		="white--text"
				      rounded
                      :disabled = "loading.generate === 'Loading...'?true:false"
                      @click="Loading"
				    >
				      <v-icon dark>{{$vars.V('icon_generate')}}</v-icon>{{ loading.generate }}
				    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        </v-tab-item>
        </v-tabs>
        <!-- Dialog -->
        <v-dialog v-model="remarks.dialog" fullscreen hide-overlay transition="dialog-bottom-transition" persistent max-width="290" v-if="remarks.feedback !== ''">
		<v-card>
			<v-toolbar dark color="fourth" dense>
			<v-btn icon dark @click="remarks.dialog = false">
				<v-icon>{{$vars.V('icon_close')}}</v-icon>
			</v-btn>
			<v-toolbar-title>Error Messages</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="mt-2 ml-n2">
			  <div class="subheading font-weight-bold" v-html="remarks.feedback"></div>
			</v-card-text>
		</v-card>
	    </v-dialog>
        <!-- Snackbar -->
        <v-snackbar
            v-model	= "snackbar.dialog"
            :timeout= "snackbar.timeout"
            color	= "fourth"
            rounded	= "pill"
            top
            style 	= "z-index: 9999"
            >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
            <v-btn dark text @click.native="remarks.dialog = true" small v-if="snackbar.color === 'error'">Open</v-btn>
            <v-btn dark text @click.native="snackbar.dialog = false" small>Close</v-btn>
            </template>
        </v-snackbar>
        <v-dialog v-model="confirm.dialog" max-width="490" hide-overlay persistent>
            <v-card>
                <v-card-title class="primary white--text">{{confirm.title}}?</v-card-title>
                <v-card-text>
                    
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn id="dialog" color="primary"
                        :disabled   = "confirm.text === 'Ok' ? false:true"
                        @click      = "Loading">{{confirm.text}}</v-btn>
                    <v-btn color="error" @click="confirm.dialog=false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    data: () => ({
	  	snackbar: {
			dialog: false,
			color: 'success',
			text: '',
			timeout: 3000
		},
		modal:{
			asat: '',
		},
		confirm: {
			dialog: false,
			title: '',
			text: 'Ok',
		},
		loading: {
          page: true,
          generate: true,
		},
		form: {
			access: 0,
			asat: ''
		},
		remarks: {
			dialog: false
		}
    }),
    created () {
		this.form.asat = this.$functions.TodayYYYYMMDD()
		this.loading.generate = this.$vars.V('txt_generate')
        this.Access()
    },
    methods: {
        //this function use for Checking the user privilleges with access for Create, Read, Update, Delete (CRUD) Data
		Access() {
			let modul = "profile"
			let formdata = {
				menu_id: modul,
				users_id: this.$functions.UsersID(),
				order_by: "menu_id",
				order_type: "ASC",
				limit: this.limit,
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiListMenuUser"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let priv = response.data
				if (priv.length > 0) {
					if (priv[0].usersmenu_add === 0) {
						this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
					} else {
                        this.form.access = priv[0].usersmenu_add
                    }
				} else {
					this.SnackBar(true, "error", this.$functions.NoPriviledge(modul), 0)
				}
				this.loading.page = false
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.page = false
			})
		},
        Loading () {
			this.loading.generate = "Loading..."
			this.Search()
		},
        Search ()  {
			let formdata = {
				report_asat: this.form.asat,
				order_by: "outstanding_certno",
				order_type: "ASC",
			}
			let param = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST("apiReportOutstanding"), param, {
				headers: {
					"Content-Type": "text/plain charset=ISO-8859-1",
				},
			})
			.then((response) => {
				let feedback = response.data
				if (feedback.length > 0) {
					if (feedback[0].feedback !== "Y") {
						this.SnackBar(true, "error", feedback[0].feedback, 3000)
					} else {
						window.open(feedback[0].download)
					}
				} else {
					this.SnackBar(true, "error", this.$vars.V('txt_not_found'), 3000)
				}
				this.loading.generate = this.$vars.V('txt_generate')
			})
			.catch((e) => {
				this.SnackBar(true, "error", e, 0)
				this.loading.generate = this.$vars.V('txt_generate')
			})
        },
        SnackBar(dialog, color, text, timeout) {
			this.snackbar = {
				dialog: dialog,
				color: color,
				text: text,
				timeout: timeout,
			}
			this.confirm.dialog 	= false
            this.confirm.text 		= "Ok"
		},
    }
}
</script>