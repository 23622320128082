<template>
  <div id="app">
  <app-offline @detected-condition="CheckConnectivityChange"></app-offline>
  <!-- Check has a been login and store in local storage or not -->
    <v-app id="inspire" v-if="check_login === null">
      <v-main class="bg-login-page">
      <v-container
        fluid
        :style="$vuetify.breakpoint.xs === true || $vuetify.breakpoint.sm === true ?'':'margin-left:310px;margin-top:120px'"
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <div style="text-align: center;" v-if="$vuetify.breakpoint.xs === true || $vuetify.breakpoint.sm === true"><img src="../public/logo-orion-new.jpeg" width="47%"></div>
            <v-card outlined rounded>
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-toolbar-title>{{app_title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :href="source"
                      icon
                      large
                      target="_blank"
                      v-on="on"
                    >
                      <v-icon>vpn_key</v-icon>
                    </v-btn>
                  </template>
                  <span>Source</span>
                </v-tooltip>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="form_users" ref="form" lazy-validation>
                    <v-text-field 
                      prepend-icon="person" 
                      v-model="users_id" 
                      :rules="users_id_rules"
                      required 
                      label="Username"
                      type="text"
                      autofocus>
                    </v-text-field>
                    <v-text-field
                      prepend-icon="lock"
                      v-model="users_password"
                      :rules="users_password_rules"
                      required
                      :append-icon="users_password_hint ? 'visibility_off' : 'visibility'"
                      @click:append="() => (users_password_hint = !users_password_hint)"
                      :type="users_password_hint ? 'text' : 'password'"
                      label="Password"
                      id="password"
                      @keyup.enter="Login"
                      >
                    </v-text-field>
                    <!-- <vue-recaptcha 
                    sitekey="6LdFFOkeAAAAAHOMZs0d05Q12L-rUrw12Ecjn6I-"
                    ref="invisibleRecaptcha"
                    @verify="Login"
                    ></vue-recaptcha> -->
                </v-form>
                <!-- <v-btn color="secondary" rounded block @click="Login">Login</v-btn> -->
              </v-card-text>
              <v-card-actions>
                <span class="red--text">Version {{app_version}}</span>
                <v-spacer></v-spacer>
                <span @click="ForgotPassword">Forget Password</span>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <!-- Dialog When Forgot Password -->
    <v-dialog v-model="dialog_forgot_password" max-width="490" persistent>
      <v-card>
          <v-card-title class="primary white--text">Masukkan Email</v-card-title>
          <v-card-text>
            <v-form enctype="multipart/form-data" ref="form_forgotpassword" lazy-validation onSubmit="return false">
              <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-text-field
                  v-model       ="form.users_email"
                  required
                  :rules        ="form.users_email_rules"
                  label         ="Your Email *"
                  placeholder   ="Please fill Your Email"
                  id            ="forgotpassword"
                  v-on:keyup.enter  ="Loading('forgotpassword')"
                  >
                  </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="dialog" 
                  color="primary"
                  :disabled   ="confirm.text === 'Ok' ? false:true"
                  @click      ="Loading('forgotpassword')">{{confirm.text}}</v-btn>
              <v-btn @click="dialog_forgot_password=false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog When Expiry Password -->
    <v-dialog v-model="dialog_expiry_password" fullscreen persistent>
      <v-card>
          <v-card-title class="primary white--text">Change Password</v-card-title>
          <v-card-text>
            <v-form enctype="multipart/form-data" ref="form_changepassword" lazy-validation>
              <v-row>
              <v-col cols="12" sm="12" md="12">
                  <v-text-field
                  v-model       ="form.old_password"
                  :type         ="form.old_password_show ? 'text' : 'password'"
                  required
                  :rules        ="form.old_password_rules"
                  label         ="Old Password *"
                  placeholder   ="Old Password"
                  id            ="old_password"
                  @keyup.enter  ="Loading('changepassword')"
                  :append-icon  ="form.old_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append ="form.old_password_show = !form.old_password_show"
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" :class="$vuetify.breakpoint.xs?'':'mt-n5'">
                  <v-text-field
                  v-model       ="form.new_password"
                  :type         ="form.new_password_show ? 'text' : 'password'"
                  required
                  :rules        ="form.new_password_rules"
                  label         ="New Password *"
                  placeholder   ="New Password"
                  id            ="new_password"
                  @keyup.enter  ="Loading('changepassword')"
                  :append-icon  ="form.new_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append ="form.new_password_show = !form.new_password_show"
                  >
                  </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12" :class="$vuetify.breakpoint.xs?'':'mt-n5'">
                  <v-text-field
                  v-model       ="form.confirm_password"
                  :type         ="form.confirm_password_show ? 'text' : 'password'"
                  required
                  :rules        ="form.confirm_password_rules"
                  label         ="Confirm Password *"
                  placeholder   ="Confirm Password"
                  id            ="confirm_password"
                  @keyup.enter  ="Loading('changepassword')"
                  :append-icon  ="form.confirm_password_show ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append ="form.confirm_password_show = !form.confirm_password_show"
                  >
                  </v-text-field>
              </v-col>
            </v-row>
          </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn id="dialog" 
                  color="primary"
                  :disabled   ="confirm.text === 'Ok' ? false:true"
                  @click      ="Loading('changepassword')">{{confirm.text}}</v-btn>
              <v-btn @click="dialog_expiry_password=false">Close</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
      <v-snackbar 
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      v-model="snackbar.dialog"
      rounded = "pill"
      bottom
      style   = "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn text @click.native="snackbar.dialog = false">Close</v-btn>
      </template>
  </v-snackbar>
    </v-app>
    <v-app id="inspire" v-else>
    <v-main>
      <v-container fluid>
        <v-fade-transition mode="out-in">
        <router-view></router-view>
        </v-fade-transition>
      </v-container>
      <p class="text-center"><small>&copy; {{$functions.TodayYear()}} {{$vars.V('txt_footer')}}</small></p>
    </v-main>
      <v-snackbar 
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      v-model="snackbar.dialog"
      rounded = "pill"
      bottom
      style   = "z-index: 9999"
      >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
      <v-btn text @click.native="snackbar.dialog = false">Close</v-btn>
      </template>
  </v-snackbar>
  </v-app>
  </div>
</template>
<script>
// import { VueRecaptcha } from 'vue-recaptcha'
  export default {
    data: () => ({
      check_login: null,
      dialog_forgot_password: false,
      dialog_expiry_password: false,
      //SnackBar
      snackbar: {
        dialog: false,
        color: 'success',
        text: '',
        timeout: 3000,
      },
      confirm: {
        dialog: false,
        title: "",
        text: "Ok",
      },
      //Validation Form Users
      app_title: '',
      app_version: '',
      feeds:[],
      form_users: true,
      users_id: '',
      users_id_rules: [
        v => !!v || 'Username is required'
      ],
      users_password: '',
      users_password_rules: [
        v => !!v || 'Password is required'
      ],
      users_password_hint: false,
      form: {
        password: '',
        password_show: false,
        password_rules: [(v) => !!v || "Please fill Password"],
        users_email: '',
        users_email_rules:[
          v => !!v || 'Email is required',
          v => /.+@.+\..+/.test(v) || 'Email must be valid'
        ],
        old_password: '',
        new_password: '',
        confirm_password: '',
        old_password_show: false,
        new_password_show: false,
        confirm_password_show: false,
        old_password_rules: [(v) => !!v || "Please fill Old Password"],
        new_password_rules: [(v) => !!v || "Please fill New Password"],
        confirm_password_rules: [(v) => !!v || "Please fill Confirm Password"],
      }
    }),
    created () {
      this.FirstLoad()
    },
    methods: {
    FirstLoad () {
      this.app_title   = this.$functions.AppTitle()
      this.app_version = this.$functions.AppVersion()
      this.check_login = this.$functions.CheckLogin()
    },
    CheckConnectivityChange (status) {
      if (status === false) {
        this.SnackBar(true, 'error', 'No Internet Connection', 6000)
      }
    },
    Login () {
      if (this.$refs.form.validate()) {
        this.ProcessLogin(this.users_id, this.users_password)
      }
    },
    ProcessLogin (usersid, userspassword) {
      //Call API Users
      this.app_title = 'Loading...'
      let formdata = {
				url_type: 'login',
        users_id: usersid,
        users_password: userspassword,
			}
	  	let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
				let feedback = response.data
        if (feedback.length > 0) {
  				if (feedback[0].feedback === 'Y') {
            // Local Users
              localStorage.setItem('local_core_users', usersid)
              localStorage.setItem('local_core_session_timeout', feedback[0].session_time)
              localStorage.setItem('local_core_login', 'Y')
              // Users Detail
              this.UsersDetail(usersid)
              // Message
              this.SnackBar(true, 'success', 'Login is Successfully', 0)
              let defaultpage = feedback[0].default_page
              //Set item to Local Storage
              this.$router.push({ name: defaultpage })
              this.ReloadAccount()
  	  			} else {
              if (feedback[0].feedback.substring(0, 4) ==='LDAP') {
                this.SnackBar(true, 'error', 'Wrong Password', 0)
              } else if (feedback[0].feedback === '') {
                this.SnackBar(true, 'error', 'User ID `' + usersid + '` Not Found', 0)
              } else {
                if (feedback[0].feedback === 'Expiry Password') {
                  // setTimeout(() => {
                  //     this.$refs.invisibleRecaptcha.reset()
                  // }, 1000)
                  this.SnackBar(true, 'error', feedback[0].feedback, 0)
                  this.dialog_expiry_password = true
                  setTimeout(function(){
                    document.getElementById('old_password').focus()
                  }, 500)
                } else {
              // this.Error(feedback[0].feedback)
                    // setTimeout(() => {
                    //   this.$refs.invisibleRecaptcha.reset()
                    // }, 1000)
                    this.SnackBar(true, 'error', feedback[0].feedback, 0)
                }
              }
              // alert(feedback[0].feedback)
            }
        } else {
          // setTimeout(() => {
          //     this.$refs.invisibleRecaptcha.reset()
          // }, 1000)
          this.SnackBar(true, 'error', 'Login is Failed', 0)
        }
        this.app_title = this.$functions.AppTitle()
			})
      .catch(e => {
          // setTimeout(() => {
          //   this.$refs.invisibleRecaptcha.reset()
          // }, 1000)
          this.app_title = this.$functions.AppTitle()
          this.SnackBar(true, 'error', e, 0)
      })
    },
    ReloadAccount () {
      let formdata = {
        users_id: this.$functions.UsersID(),
        users_flag: 'reload',
      };
      let param = this.$functions.ParamPOST(formdata);
      this.$axios
        .post(this.$functions.UrlPOST("apiListUsersShort"), param, {
          headers: {
            "Content-Type": "text/plain; charset=ISO-8859-1",
          },
        })
        .then((response) => {
          let feedback = response.data;
          if (feedback.length > 0) {
            if (feedback[0].feedback === "Y") {
              localStorage.removeItem("local_core_users_detail")
              localStorage.setItem('local_core_users_detail', JSON.stringify(feedback))
              this.SnackBar(true, "success", "Login is Successfully", 0)
              setTimeout(function () {
                location.reload()
              }, 1000);
            } else {
              this.SnackBar(true, "error", feedback[0].feedback, 0);
            }
          } else {
            this.SnackBar(true, "error", "Login is Failed", 0);
          }
        })
        .catch((e) => {
          this.SnackBar(true, "error", e, 0);
        });
    },
    UsersDetail (id) {
			let formdata = {
					users_id: id,
					users_employeef: 'Y',
					order_by: 'users_id',
					order_type: 'ASC',
					limit: this.limit,
			}
			let param 	   = this.$functions.ParamPOST(formdata)
			this.$axios.post(this.$functions.UrlPOST('apiListUsersShort'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
			})
			.then(response => {
        this.items = response.data
        localStorage.setItem('local_core_users_detail', JSON.stringify(this.items))
			})
			.catch(e => {
				this.SnackBar(true, 'error', e, 3000)
			})
		},
    Loading(url_type) {
      this.confirm.text ="Loading..."
      if (url_type ==="forgotpassword") {
        if (this.$refs.form_forgotpassword.validate()) {
          this.ForgotPasswordProcess()
        } else {
          this.confirm.text ="Ok"
        }
      } else if (url_type ==="changepassword") {
        if (this.$refs.form_changepassword.validate()) {
          this.ChangePassword()
        } else {
          this.confirm.text ="Ok"
        }
      }
    },
    // Call Dialog Forgot Password
    ForgotPassword () {
      this.dialog_forgot_password = true
      setTimeout(function(){
        document.getElementById('forgotpassword').focus()
      }, 500)
    },
    // Process Forgot Password from Email
    ForgotPasswordProcess () {
      let formdata = {
        url_type: 'resetpassword',
        users_email: this.form.users_email
      }
      let param      = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
              this.dialog_forgot_password = false
              this.confirm.text = 'Ok'
              this.form.users_email = ''
              this.SnackBar(true, 'success', 'Reset Password Successfully, Check Your Email', 0)
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.confirm.dialog = true
            }
        } else {
          this.SnackBar(true, 'error', 'Reset Password is Failed', 0)
          this.confirm.dialog = true
        }
      })
      .catch(e => {
          this.SnackBar(true, 'error', e, 0)
          this.confirm.dialog = true
      })
    },
    // Change Password User
    ChangePassword () {
      let formdata = {
        url_type: 'changepassword',
        old_password: this.form.old_password,
        new_password: this.form.new_password,
        confirm_password: this.form.confirm_password,
        users_id: this.users_id
      }
      let param      = this.$functions.ParamPOST(formdata)
      this.$axios.post(this.$functions.UrlPOST('apiWriteUsers'),param,{
        headers: {
            'Content-Type': 'text/plain; charset=ISO-8859-1'
        }
      })
      .then(response => {
        let feedback = response.data
        if (feedback.length > 0) {
          if (feedback[0].feedback === 'Y') {
              this.dialog_expiry_password = false
              this.form.old_password = ''
              this.form.new_password = ''
              this.form.confirm_password = ''
              this.confirm.text = 'Ok'
              this.SnackBar(true, 'success', 'Change Password Successfully', 0)
            } else {
              this.SnackBar(true, 'error', feedback[0].feedback, 0)
              this.confirm.dialog = true
            }
        } else {
          this.SnackBar(true, 'error', 'Login is Failed', 0)
          this.confirm.dialog = true
        }
      })
      .catch(e => {
          this.SnackBar(true, 'error', e, 0)
          this.confirm.dialog = true
      })
    },
    SnackBar(dialog, color, text, timeout) {
      this.snackbar = {
        dialog: dialog,
        color: color,
        text: text,
        timeout: timeout,
      }
      if (color ==="error") {
        this.confirm.dialog   = false
        this.confirm.text     ="Ok"
      }
    },
    },
    // components: {
    //   VueRecaptcha
    // },
    props: {
      source: String
    }
  }
</script>